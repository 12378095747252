const courseSettings = {
  language: "en",
  name: "Big Data Platforms, Autumn 2021",
  siteUrl: "https://big-data-platforms-21.mooc.fi/",
  githubUrl: "https://github.com/rage/big-data-platforms-21/",
  subtitle: "Introduction to Big Data Processing Frameworks",
  slug: "big-data-platforms",
  tmcCourse: "",
  quizzesId: "da8dddb6-5478-44e7-9e71-1738601cb168",
  tmcOrganization: "hy",
  organizationName: "University of Helsinki",
  bannerPath: "banner.jpg",
  courseId: "b8ff329d-5085-45f2-a26e-40218797d98c",
}

module.exports = courseSettings
